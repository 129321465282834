/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react";
import {globalHistory} from '@reach/router';

import 'jquery'
import 'jquery/dist/jquery.min'
import $ from 'jquery'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import AOS from 'aos/dist/aos'
import SmoothScroll from 'smooth-scroll/dist/smooth-scroll.min'

import {jarallax, jarallaxVideo} from 'jarallax';

import Typed from 'typed.js/src/typed'
import {CountUp} from 'countup.js'
import hljs from 'highlight.js'

import 'bootstrap/dist/css/bootstrap.min.css'
import "./src/fonts/Feather/feather.css"
import "./src/../static/libs/flickity/dist/flickity.min.css"
import "./src/../static/libs/flickity-fade/flickity-fade.css"
import "./src/../static/libs/aos/dist/aos.css"
import "./src/../static/libs/jarallax/dist/jarallax.css"
import "./src/../static/libs/highlightjs/styles/vs2015.css"
import "./src/../static/libs/@fancyapps/fancybox/dist/jquery.fancybox.min.css"
import "./src/css/bootstrap.min.css"

import "./src/css/style.css"
import 'isotope-layout/dist/isotope.pkgd.min'
import 'imagesloaded/imagesloaded.pkgd.min'
import 'rellax/rellax.min'
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import {documentReady, gtagCustomEvent, live} from "./src/utils/global-functions";
import scrollTo from "gatsby-plugin-smoothscroll";

console.log('                 ((((                                       ((((                \n' +
    '         (((((((((((((((((((                         ((((((((((((((((((         \n' +
    '      (((((((((((((((((((((((((                  ((((((((((((((((((((((((((     \n' +
    '    ((((((((((         ((((((((((             ((((((((((((        (((((((((((   \n' +
    '  (((((((((                ((((((          (((((((((((                ((((((((( \n' +
    ' ((((((((                    ((          (((((((((                      ((((((((\n' +
    ' (((((((                              ((((((((((                         (((((((\n' +
    '(((((((                             (((((((((                             ((((((\n' +
    ' ((((((                           (((((((((                              (((((((\n' +
    ' (((((((                       ((((((((((                                (((((((\n' +
    '  ((((((((                   ((((((((((           (((                  (((((((((\n' +
    '   (((((((((             (((((((((((            ((((((((             (((((((((((\n' +
    '     ((((((((((((((((((((((((((((               ((((((((((((((((((((((((((((((((\n' +
    '        ((((((((((((((((((((((                     (((((((((((((((((((((( ((((((\n' +
    '            (((((((((((((                              ((((((((((((((     ((((((');
console.log('We´re hiring!');
console.log('https://tecalis.com/es/talento/ofertas-empleo');

if (typeof window !== `undefined`) {
    window.$ = $;
    window.jQuery = $;
}

window.onLoadCaptcha = onLoadCaptcha;
window.checkCaptchaForm = checkCaptchaForm;

function onLoadCaptcha() {
    $('#contact-form button[type=submit]').on('click', checkCaptchaForm);
    $('#sales-form button[type=submit]').on('click', checkCaptchaForm);
    $('#curriculo-form button[type=submit]').on('click', checkCaptchaForm);
}

function checkCaptchaForm() {
    console.log("checkCaptchaForm");

    let f = document.getElementsByTagName('form')[0];
    if (f.checkValidity()) {
        setTimeout(function () {
            f.submit();
        }, 1500);
    } else {
        f.reportValidity();
    }
}

documentReady(() => {
    live('.smooth-scroll', 'click', (e, element) => {
        if (element) {
            const href = element.getAttribute('href').replace('/', '');

            if (href) {
                e.preventDefault();
                scrollTo(href);
            }
        }
    });
});

export const onInitialClientRender = () => {
    /**
     * This is a workaround for a bug in Gatsby
     *
     * See https://github.com/gatsbyjs/gatsby/issues/8357 for more details
     */
    globalHistory._onTransitionComplete();
    // onLoadCaptcha();
};

/*export const onServiceWorkerUpdateReady = () => {
    const answer = window.confirm(
        `Esta aplicación ha sido actualizada. ¿Volver a cargar para mostrar la última versión?`
    )
    if (answer === true) {
        window.location.reload()
    }
}*/

export const onRouteUpdate = () => {

        // Bloqueo del menú contextual en vídeos
        $(document).on('contextmenu', 'video', function () {
            return false;
        });

        onLoadCaptcha();

        // jarallax de banners de vídeo
        jarallaxVideo();
        jarallax(document.querySelectorAll('.jarallax'), {
            speed: 0.8,
        });

        let queryString = window.location.search;
        let urlParams = new URLSearchParams(queryString);

        // aviso de cookies
        documentReady(() => {
            if (document.cookie.indexOf("ModalCookieAccepted=true") < 0) {
                setTimeout(() => {
                    $('#cookieModal').modal({'show': true, 'backdrop': 'static', 'keyboard': false});
                }, 3000);

                // Modal close when go to privacy page
                $("#cookieModalPrivacyButton").on('click', function () {
                    $('#cookieModal').modal('hide');
                });

                //Modal has been shown, now set a cookie so it never comes back
                $("#cookieModalCloseButton").click(function () {
                    document.cookie = "ModalCookieAccepted=true; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/";

                    if (urlParams.get('rg') === 'dexga') {
                        $('#dexgaModal').modal('show');
                    }
                });
            } else {
                // aviso de Dexga es Tecalis
                if (urlParams.get('rg') === 'dexga') {
                    $('#dexgaModal').modal('show');
                }
            }
        });

        // muestra logo fondos feder para siemrpe si se recibe el parámetro
        let showFeder = urlParams.has('fd');
        if (showFeder || document.cookie.indexOf("RefererTecalisEs=true") >= 0) {
            $('#ocultar').removeClass('d-none');
            document.cookie = "RefererTecalisEs=true; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/";
        } else {
            $('#ocultar').addClass('d-none');
        }

        $('#ocultar').on('click', '.close--eu', function () {
            $(this).parent().fadeOut();
            $(this).parent().removeClass('d-none');
        });


        // muestra partners para siempre si se recibe el parámetro
        // let showPartners = urlParams.has('pt');
        // if (showPartners || document.cookie.indexOf("ShowPartners=true") >= 0) {
        //     $('#partners').removeClass('d-none');
        //     document.cookie = "ShowPartners=true; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/";
        // } else {
        //     $('#partners').addClass('d-none');
        // }

        // Hack para eliminar el background del modal de cookies al cambiar de páginas
        if (!$('#cookieModal').length && $(".modal-backdrop.fade.show").length === 1) {
            $(".modal-backdrop.fade.show").first().fadeOut();
        }
        if ($('#cookieModal').length && $(".modal-backdrop.fade.show").length > 1) {
            $(".modal-backdrop.fade.show").first().remove();
        }

        // scripts web
        $(window).on("load resize scroll", function () {
            onLoadResizeScroll();
        });

        onLoadResizeScroll();

        let $timeline_block = $('.cd-timeline-block');

//hide timeline blocks which are outside the viewport
        $timeline_block.each(function () {
            if ($(this).offset().top > $(window).scrollTop() + $(window).height() * 0.75) {
                $(this).find('.cd-timeline-img, .cd-timeline-content').addClass('is-hidden');
            }
        });

//on scolling, show/animate timeline blocks when enter the viewport
        $(window).on('scroll', function () {
            $timeline_block.each(function () {
                if ($(this).offset().top <= $(window).scrollTop() + $(window).height() * 0.75 && $(this).find('.cd-timeline-img').hasClass('is-hidden')) {
                    $(this).find('.cd-timeline-img, .cd-timeline-content').removeClass('is-hidden').addClass('bounce-in');
                }
            });
        });


// feder button
        $(".feder").delay(10000).fadeOut(500);

        function onLoadResizeScroll() {
            $(".bg-static").each(function () {
                const windowTop = $(window).scrollTop();
                const elementTop = $(this).offset().top;
                const leftPosition = windowTop - elementTop;
                const rightPosition = windowTop - elementTop;

                $(this)
                    .find(".bg-move")
                    .css({left: leftPosition});

                $(this)
                    .find(".bg-move-r")
                    .css({right: rightPosition});

                $(this)
                    .find(".bg-move-home")
                    .css({left: leftPosition});
            });
        }

        // Botones file
        $('input[name=curriculumFile]').on('change', function () {
            let file = $(this)[0].files[0];
            if (file) {
                $(this).closest('form').find('.js-file-name').html(file.name);
            }
        });

        AOS.init({
            disable: 'mobile',
            duration: 700,
            easing: "ease-out-quad",
            once: !0
        });

        (() => {
            let e = document.querySelectorAll(".navbar-nav .dropdown, .navbar-nav .dropright");
            [].forEach.call(e, function (a) {
                "mouseenter mouseleave click".split(" ").forEach(function (t) {
                    a.addEventListener(t, function () {
                        if (991 < window.innerWidth) {
                            let e = a.querySelector('[data-toggle="dropdown"]');
                            if ("mouseenter" === t) {
                                a.classList.add("hovered");
                                $(e).dropdown("show");
                            } else {
                                $(e).dropdown("hide");
                                e.blur();
                            }
                        }
                    })
                })
            })
        })();

        (() => {
            let e = document.querySelectorAll('[data-toggle="countup"]');

            function a(e) {
                let t = e.dataset.from ? +e.dataset.from : 0,
                    a = e.dataset.to ? +e.dataset.to : null,
                    o = e.dataset.decimals ? +e.dataset.decimals : 0,
                    n = e.dataset.duration ? +e.dataset.duration : 5,

                    l = new CountUp(e, a, {
                        duration: n,
                        startVal: t,
                        decimalPlaces: o
                    });

                if (l.error) {
                    console.error(l.error);
                } else {
                    l.start();
                    e.classList.add("counted");
                }
            }

            "undefined" != typeof CountUp && e && [].forEach.call(e, function (e) {
                a(e);
            });

            document.addEventListener("aos:in:countup:in", function (e) {
                if (e.detail instanceof Element) {
                    a(e.detail);
                } else {
                    let t = document.querySelectorAll('.aos-animate[data-aos-id="countup:in"]:not(.counted)');
                    [].forEach.call(t, function (e) {
                        a(e);
                    })
                }
            });
        })();

        (() => {
            let e = document.querySelectorAll(".highlight");
            "undefined" != typeof hljs && e && [].forEach.call(e, function (e) {
                hljs.highlightBlock(e)
            })
        })();

        (() => {
            let t = document.querySelector(".navbar"),
                a = !1,
                o = !!t && t.classList.contains("navbar-togglable");

            function n() {
                if (a && o) {
                    t.classList.remove("navbar-light");
                    t.classList.remove("bg-white");
                    t.classList.remove("border-bottom");
                    t.classList.add("navbar-dark");
                    t.style.display = "none";
                    t.style.display = "block";
                    a = !1;
                }
            }

            function r() {
                let e = window.pageYOffset;
                if (0 < e && !a) {
                    if (!a && o) {
                        t.classList.remove("navbar-dark");
                        t.classList.add("navbar-light");
                        t.classList.add("bg-white");
                        t.classList.add("border-bottom");
                        a = !0
                    }
                } else {
                    (0 === e || (e < 0 && a)) && n()
                }
            }

            t && o && "load scroll".split(" ").forEach(function (e) {
                window.addEventListener(e, function (e) {
                    r();
                })
            })
        })();

        (() => {
            let e = document.querySelectorAll('[data-toggle="popover"]');
            e && $(e).popover({
                template: '<div class="popover" role="tooltip"><div class="arrow"></div><h6 class="popover-header text-uppercase"/><div class="popover-body"></div></div>'
            })
        })();

        (() => {
            let e = document.querySelector('[data-toggle="price"]');
            let hideMe = document.querySelectorAll('.hideme');

            "undefined" != typeof CountUp && e && e.addEventListener("change", (e) => {
                let t = e.target,
                    d = t.checked,
                    a = t.dataset.target,
                    o = document.querySelectorAll(a);

                if (d) {
                    hideMe.forEach(element => element.style.display = 'none');
                } else {
                    hideMe.forEach(element => element.style.display = '');
                }

                [].forEach.call(o, function (e) {
                    let a = parseFloat(e.dataset.annual),
                        m = parseFloat(e.dataset.monthly),
                        f = e.dataset.decimal === 'true',
                        r = e.dataset.options ? JSON.parse(e.dataset.options) : {startVal: d ? a : m, decimalPlaces: f ? 2 : 0, separator: ''},
                        l = d ? new CountUp(e, m, r) : new CountUp(e, a, r);
                    l.error ? console.error(l.error) : l.start()
                })
            })
        })();

        (() => {
            let e = '[data-toggle="smooth-scroll"]';
            "undefined" != typeof SmoothScroll && new SmoothScroll(e, {
                header: ".navbar.fixed-top",
                offset: function (e, t) {
                    return t.dataset.offset ? t.dataset.offset : 24
                }
            })
        })();

        (() => {
            let e = document.querySelectorAll('[data-toggle="tooltip"]');
            e && $(e).tooltip()
        })();

        (() => {
            let e = document.querySelectorAll('[data-toggle="typed"]');

            "undefined" != typeof Typed && e && [].forEach.call(e, function (e) {
                let t = e.dataset.options;
                if (t) {
                    t = JSON.parse(t);
                } else {
                    t = {};
                }
                const a = Object.assign({
                    typeSpeed: 40,
                    backSpeed: 40,
                    backDelay: 1e3,
                    loop: !0
                }, t);
                new Typed(e, a);
            })
        })();

        (() => {
            let e = document.querySelectorAll(".navbar-nav .dropdown, .navbar-nav .dropright");

            [].forEach.call(e, function (a) {
                "mouseenter mouseleave click".split(" ").forEach(function (t) {
                    a.addEventListener(t, function () {
                        if (991 < window.innerWidth) {
                            let e = a.querySelector('[data-toggle="dropdown"]');
                            if ("mouseenter" === t) {
                                a.classList.add("hovered");
                                $(e).dropdown("show");
                            } else {
                                $(e).dropdown("hide");
                                e.blur();
                            }
                        }
                    })
                })
            })
        })();

        (() => {
            function shuffle(a) {
                let j, x, i;
                for (i = a.length - 1; i > 0; i--) {
                    j = Math.floor(Math.random() * (i + 1));
                    x = a[i];
                    a[i] = a[j];
                    a[j] = x;
                }
                return a;
            }

            // Game, juego easter egg
            const soundSuccess = new Audio(require("./src/sounds/ok.wav").default);
            const soundError = new Audio(require("./src/sounds/wrong.mp3").default);
            let solution = [];
            let clicksRequired = 5;
            for (let i = 0; i < clicksRequired; i++) {
                solution.push(Math.floor(Math.random() * 3) + 1)
            }
            solution = shuffle(solution);
            let combination = [];

            function openGame() {
                window.open("/game-tecalis/index.xhtml", "Tecalis Challenge", "width=1100, height=734, top: 300; left: 300;");
            }

            $(".game--cards").on('click', function (e) {
                e.preventDefault();
                let selectedItem = $(this).data('card');

                if (selectedItem === solution[combination.length]) {
                    selectSuccess($(this));
                    combination.push(selectedItem);

                    if (JSON.stringify(combination) === JSON.stringify(solution)) {
                        openGame();
                        combination = [];
                    }
                } else {
                    selectError($(this));
                    combination = [];
                }
            });

            function selectSuccess($card) {
                $card.find('.after-success').fadeIn('fast', function () {
                    $(this).fadeOut();
                });
                soundSuccess.play();
            }

            function selectError($card) {
                $card.find('.after-error').fadeIn('fast', function () {
                    $(this).fadeOut();
                });
                soundError.play();
            }
        })();

        (() => {
            'use strict';

            const mobile = navigator.userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|IEMobile/i);

            if (!mobile) {
                startVideoBanner();
            } else {
                showFallback();
            }

            function showFallback() {
                let fallback = $('.video-wrapper .fallback-container'),
                    fallbackElements = fallback.find('[data-animation-percent]'),
                    currentTime = 0,
                    animationDuration = 5000,
                    timeInterval = 50;

                fallback.addClass('active');

                let fallbackInterval = setInterval(function () {
                    currentTime = (parseInt(currentTime) + timeInterval);
                    fallbackElements.each(function () {
                        let animationPercent = parseInt($(this).attr('data-animation-percent'));
                        if ((currentTime / animationDuration * 100) >= animationPercent) {
                            $(this).addClass('active');
                        } else {
                            $(this).removeClass('active');
                        }
                    });

                    if (fallbackElements.filter('.active').length !== 0) {
                        fallbackElements.parents('.caption').addClass('active');
                    }

                    if (currentTime >= animationDuration) {
                        clearInterval(fallbackInterval);
                    }

                }, timeInterval);
            }

            function startVideoBanner() {
                let videos = $('.video-container');
                let videosOnScroll = [];

                videos.each(function (index) {
                    let video = $(this).find('video'), nextVideo;
                    let positionPlayStar = $(this).offset().top;
                    let positionPlayEnd = $(this).offset().top + ($(this).height());

                    videosOnScroll.push({
                        positionPlayStar: positionPlayStar,
                        positionPlayEnd: positionPlayEnd,
                        video: video[0]
                    })

                    /*$(this).on('mouseenter', function () {
                        video[0].play();
                    });

                    $(this).on('mouseleave', function () {
                        video[0].pause();
                    });*/

                    if (index !== (videos.length - 1)) {
                        nextVideo = $(this).next('.video-container').find('video');
                    } else {
                        nextVideo = videos.first().find('video');
                    }

                    if (index === 0) {
                        video.parents('.video-container').addClass('active');
                        video[0].preload = 'auto';
                        video[0].pause();
                    }

                    let caption = video.siblings('.caption'),
                        captionItems = caption.find('[data-animation-percent]'),
                        videoBar = video.siblings('.progress-bar'),
                        dragging = false,
                        nextLoaded = false;

                    $(video).on('timeupdate', function () {
                        let videoTime = ((this.currentTime / this.duration) * 100);

                        if (captionItems.length > 0) {
                            captionItems.each(function () {
                                let item = $(this);
                                let animTime = parseInt(item.attr('data-animation-percent'));

                                if (videoTime >= animTime) {
                                    item.addClass('active');
                                } else {
                                    item.removeClass('active');
                                }
                            });

                            if (captionItems.filter('.active').length !== 0) {
                                caption.addClass('active');
                            } else {
                                caption.removeClass('active');
                            }

                            if (videoTime >= 90) {
                                caption.removeClass('active');

                                captionItems.each(function () {
                                    $(this).removeClass('active');
                                });
                            }
                        }

                        if (videoTime >= 70 && nextLoaded === false) {
                            /*nextVideo.preload = 'auto';
                            nextVideo.load();*/
                            nextLoaded = true;
                        }
                    });

                    function updateProgressAuto(video) {
                        let videoBar = $(video).siblings('.progress-bar');
                        let videoPercent = ((video[0].currentTime / video[0].duration) * 100);

                        videoBar.find('.progress').css('width', videoPercent + '%');
                        videoBar.find('.progress-value').html(parseFloat(video[0].currentTime).toFixed(2) + ' : ' + parseFloat(video[0].duration).toFixed(2));
                        videoBar.find('.progress-time').html(parseInt(videoPercent) + '%');
                    }

                    setInterval(function () {
                        updateProgressAuto(video);
                    }, 100);

                    video[0].onended = function () {
                        nextVideo.parents('.video-container').addClass('active');
                        video.parents('.video-container').removeClass('active');
                        nextVideo[0].play();
                    };

                    function updateProgressManual(progressBarPosition, video) {
                        let videoBar = $(video).siblings('.progress-bar');
                        let videoPercentage = ((progressBarPosition / videoBar.outerWidth()) * 100);

                        videoBar.find('.progress').css('width', videoPercentage + '%');
                        videoBar.find('.progress-value').html(parseFloat(video[0].currentTime).toFixed(2) + ' : ' + parseFloat(video[0].duration).toFixed(2));
                        videoBar.find('.progress-time').html(parseInt(videoPercentage) + '%');
                        video[0].currentTime = ((video[0].duration * videoPercentage) / 100);
                    }


                    if (captionItems.length !== 0) {
                        video.parents('.video-container').on('mouseover', '.caption.active', function () {
                            video[0].playbackRate = 0.5;
                        });
                        video.parents('.video-container').on('mouseout', '.caption.active', function () {
                            video[0].playbackRate = 1;
                        });
                    }
                });

                $(window).on('scroll', () => {
                    let position = $(window).scrollTop() + ($(window).height() / 3);

                    videosOnScroll.forEach((video, index) => {
                        if (position >= video.positionPlayStar && position <= video.positionPlayEnd) {
                            if (video.video.paused) {
                                video.video.play();
                                console.log("Video " + index + " is playing");
                            }
                        } else if (!video.video.paused) {
                            video.video.pause();
                            console.log("Video " + index + " is paused");
                        }
                    });
                });
            }
        })();

        (() => {
            $(".modal").on("show.bs.modal", (e) => {
                gtagCustomEvent("modal.open", {name: e.currentTarget.id, from: window.location.href});
            });
        })();
    }
;

export const wrapRootElement = ({element}) => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey={process.env.RECAPTCHA_KEY}>
            {element}
        </GoogleReCaptchaProvider>
    )
}
