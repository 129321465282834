// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cloud-native-js": () => import("./../../../src/pages/cloud-native.js" /* webpackChunkName: "component---src-pages-cloud-native-js" */),
  "component---src-pages-conditions-js": () => import("./../../../src/pages/conditions.js" /* webpackChunkName: "component---src-pages-conditions-js" */),
  "component---src-pages-contact-ko-js": () => import("./../../../src/pages/contact/ko.js" /* webpackChunkName: "component---src-pages-contact-ko-js" */),
  "component---src-pages-contact-ok-js": () => import("./../../../src/pages/contact/ok.js" /* webpackChunkName: "component---src-pages-contact-ok-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-contract-conditions-js": () => import("./../../../src/pages/contract-conditions.js" /* webpackChunkName: "component---src-pages-contract-conditions-js" */),
  "component---src-pages-feder-js": () => import("./../../../src/pages/feder.js" /* webpackChunkName: "component---src-pages-feder-js" */),
  "component---src-pages-identity-casos-de-uso-js": () => import("./../../../src/pages/identity/casos-de-uso.js" /* webpackChunkName: "component---src-pages-identity-casos-de-uso-js" */),
  "component---src-pages-identity-faq-js": () => import("./../../../src/pages/identity/faq.js" /* webpackChunkName: "component---src-pages-identity-faq-js" */),
  "component---src-pages-identity-funcionalidades-js": () => import("./../../../src/pages/identity/funcionalidades.js" /* webpackChunkName: "component---src-pages-identity-funcionalidades-js" */),
  "component---src-pages-identity-index-js": () => import("./../../../src/pages/identity/index.js" /* webpackChunkName: "component---src-pages-identity-index-js" */),
  "component---src-pages-identity-legalidad-js": () => import("./../../../src/pages/identity/legalidad.js" /* webpackChunkName: "component---src-pages-identity-legalidad-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integrations-js": () => import("./../../../src/pages/integrations.js" /* webpackChunkName: "component---src-pages-integrations-js" */),
  "component---src-pages-money-2020-js": () => import("./../../../src/pages/money2020.js" /* webpackChunkName: "component---src-pages-money-2020-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-people-join-tecalis-js": () => import("./../../../src/pages/people/join-tecalis.js" /* webpackChunkName: "component---src-pages-people-join-tecalis-js" */),
  "component---src-pages-people-js": () => import("./../../../src/pages/people.js" /* webpackChunkName: "component---src-pages-people-js" */),
  "component---src-pages-people-tecalis-students-js": () => import("./../../../src/pages/people/tecalis-students.js" /* webpackChunkName: "component---src-pages-people-tecalis-students-js" */),
  "component---src-pages-politics-js": () => import("./../../../src/pages/politics.js" /* webpackChunkName: "component---src-pages-politics-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-providers-js": () => import("./../../../src/pages/providers.js" /* webpackChunkName: "component---src-pages-providers-js" */),
  "component---src-pages-quality-js": () => import("./../../../src/pages/quality.js" /* webpackChunkName: "component---src-pages-quality-js" */),
  "component---src-pages-request-a-demo-index-en-js": () => import("./../../../src/pages/request-a-demo/index.en.js" /* webpackChunkName: "component---src-pages-request-a-demo-index-en-js" */),
  "component---src-pages-request-a-demo-index-es-js": () => import("./../../../src/pages/request-a-demo/index.es.js" /* webpackChunkName: "component---src-pages-request-a-demo-index-es-js" */),
  "component---src-pages-rpa-index-js": () => import("./../../../src/pages/rpa/index.js" /* webpackChunkName: "component---src-pages-rpa-index-js" */),
  "component---src-pages-rs-corporativa-js": () => import("./../../../src/pages/rs-corporativa.js" /* webpackChunkName: "component---src-pages-rs-corporativa-js" */),
  "component---src-pages-signature-faq-js": () => import("./../../../src/pages/signature/faq.js" /* webpackChunkName: "component---src-pages-signature-faq-js" */),
  "component---src-pages-signature-funcionalidades-js": () => import("./../../../src/pages/signature/funcionalidades.js" /* webpackChunkName: "component---src-pages-signature-funcionalidades-js" */),
  "component---src-pages-signature-index-js": () => import("./../../../src/pages/signature/index.js" /* webpackChunkName: "component---src-pages-signature-index-js" */),
  "component---src-pages-signature-legalidad-js": () => import("./../../../src/pages/signature/legalidad.js" /* webpackChunkName: "component---src-pages-signature-legalidad-js" */),
  "component---src-pages-signature-precios-js": () => import("./../../../src/pages/signature/precios.js" /* webpackChunkName: "component---src-pages-signature-precios-js" */),
  "component---src-pages-sobre-tecalis-js": () => import("./../../../src/pages/sobre-tecalis.js" /* webpackChunkName: "component---src-pages-sobre-tecalis-js" */),
  "component---src-pages-strategy-js": () => import("./../../../src/pages/strategy.js" /* webpackChunkName: "component---src-pages-strategy-js" */),
  "component---src-pages-talks-js": () => import("./../../../src/pages/talks.js" /* webpackChunkName: "component---src-pages-talks-js" */),
  "component---src-pages-telecom-bundle-js": () => import("./../../../src/pages/telecom-bundle.js" /* webpackChunkName: "component---src-pages-telecom-bundle-js" */),
  "component---src-pages-why-tecalis-js": () => import("./../../../src/pages/why-tecalis.js" /* webpackChunkName: "component---src-pages-why-tecalis-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-eventos-js": () => import("./../../../src/templates/eventos.js" /* webpackChunkName: "component---src-templates-eventos-js" */),
  "component---src-templates-hubspot-blog-js": () => import("./../../../src/templates/hubspot-blog.js" /* webpackChunkName: "component---src-templates-hubspot-blog-js" */),
  "component---src-templates-job-offer-js": () => import("./../../../src/templates/job-offer.js" /* webpackChunkName: "component---src-templates-job-offer-js" */),
  "component---src-templates-medios-js": () => import("./../../../src/templates/medios.js" /* webpackChunkName: "component---src-templates-medios-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-tech-insights-js": () => import("./../../../src/templates/tech-insights.js" /* webpackChunkName: "component---src-templates-tech-insights-js" */),
  "component---src-templates-webinar-js": () => import("./../../../src/templates/webinar.js" /* webpackChunkName: "component---src-templates-webinar-js" */)
}

