import React, {useState} from "react";
import {Link} from "@reach/router";
import {OutboundLink} from "gatsby-plugin-google-gtag";

export function documentReady(fn) {
    if (typeof window === 'undefined' || !window.document) {
        return;
    }

    if (document.readyState === "complete" || document.readyState === "interactive") {
        setTimeout(fn, 1);
    } else {
        document.addEventListener("DOMContentLoaded", fn);
    }
}

export function live(selector, eventType, callback, context = null) {
    if (typeof window === 'undefined' || !window.document) {
        return;
    }

    (context || document).addEventListener(eventType, (event) => {
        const nodeList = document.querySelectorAll(selector);
        const matches = [];

        nodeList.forEach((nodeItem) => matches.push(nodeItem));

        if (matches) {
            let element = event.target;
            let index = -1;

            while (element && (index = matches.indexOf(element) === -1)) {
                element = element.parentElement;
            }

            if (index > -1) {
                callback.call(element, event, element);
            }
        }
    }, false);
}

export const useFormInput = initialValue => {
    const [value, setValue] = useState(initialValue)

    const handleChange = e => {
        setValue(e.target.value)
    }

    const clearValue = () => {
        setValue("")
    }

    return {
        value,
        clearValue,
        onChange: handleChange
    }
}

export function getCurrentPage(currentHref) {
    let currentPage = 'index';

    if (currentHref.includes('/signature') || currentHref.includes('/identity') || currentHref.includes('/rpa') || currentHref.includes('/sistemas-gestion-empresarial')) {
        currentPage = 'products';
    } else if (currentHref.includes('/desarrollo-cloud-native') || currentHref.includes('/estrategia-digital')) {
        currentPage = 'services';
    } else if (
        currentHref.includes('/sobre-tecalis') || currentHref.includes('/responsabilidad-social') || currentHref.includes('/responsabilidad-social') ||
        currentHref.includes('/politicas') || currentHref.includes('/donde-estamos') || currentHref.includes('/contacto')
    ) {
        currentPage = 'company';
    } else if (currentHref.includes('/noticias') || currentHref.includes('/insights') || currentHref.includes('/eventos') || currentHref.includes('/prensa')) {
        currentPage = 'resources';
    } else if (currentHref.includes('/talento')) {
        currentPage = 'people';
    } else if (currentHref.includes('/partners')) {
        currentPage = 'partners';
    }

    return currentPage;
}

export function gtagCustomEvent(eventName, data) {
    if (typeof window !== "undefined" && typeof window.gtag !== "undefined") {
        window.gtag("event", eventName, data);
    } else {
        console.log(eventName, data);
    }
}

export function setCookie(cname, cvalue, exdate) {
    if (typeof window === 'undefined' || !window.document) {
        return;
    }

    document.cookie = cname + "=" + cvalue + ";expires=" + exdate.toString() + ";path=/";
}

export function getCookie(cname) {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');

    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];

        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }

        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }

    return null;
}

export function customElements(pClassName = '', aClassName = 'white-link', h2ClassName = '', h4ClassName = '', liClassName = '') {
    return {
        p: ({node, ...props}) => <p className={pClassName} {...props} />,
        h2: ({node, ...props}) => <h2 className={h2ClassName} {...props} />,
        h4: ({node, ...props}) => <h4 className={h4ClassName} {...props} />,
        ul: ({node, ...props}) => <ul {...props} style={{padding: 0, listStyle: 'none'}} />,
        li: ({node, ...props}) => <li className={liClassName} {...props} />,
        a: ({node, ...props}) => {
            if (props.href.startsWith('http')) {
                return <OutboundLink href={props.href} target="_blank" rel="noopener noreferrer" className={aClassName} {...props} />
            }

            return <Link to={props.href} className={aClassName} {...props} />
        },
    }
}

export function sortAsc(a, b) {
    if (a.order > b.order) {
        return 1;
    }

    if (a.order < b.order) {
        return -1;
    }

    return 0;
}

export function sortDesc(a, b) {
    if (a.order < b.order) {
        return 1;
    }

    if (a.order > b.order) {
        return -1;
    }

    return 0;
}
